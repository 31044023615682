import { Hero } from "components/Hero";
import TicketShop from "components/TicketShop";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";

const staticImgProps = {
  alt: "",
  layout: "fullWidth",
  objectFit: "cover",
  objectPosition: "center",
};

const carouselImages = {
  0: {
    node: <StaticImage src="../images/carousel/0.png" {...staticImgProps} />,
    src: "carousel/0.png",
  },
  1: {
    node: <StaticImage src="../images/carousel/1.png" {...staticImgProps} />,
    src: "carousel/1.png",
  },
  2: {
    node: <StaticImage src="../images/carousel/2.png" {...staticImgProps} />,
    src: "carousel/2.png",
  },
  3: {
    node: <StaticImage src="../images/carousel/3.png" {...staticImgProps} />,
    src: "carousel/3.png",
  },
};

export default function IndexPage({ location }) {
  const title = "- What's The Motive - RnB, Hip-Hop, Dancehall Clubs in London";
  const description =
    "Find RnB, Hip-Hop, Bashment & Afrobeats events, Brunches, Day Parties, Clubs Night & Festivals";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <section className="home__hero">
        <Hero
          carouselImages={[
            carouselImages["0"],
            carouselImages["1"],
            carouselImages["2"],
            carouselImages["3"],
          ]}
          image={carouselImages["0"]}
        />
      </section>
      <section className="home__ticketshop">
        <TicketShop
          scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js" data-fixr-shop-id="349c6c7b-0578-4ef5-9999-8d95b36733e5" data-fixr-theme="light"></script>`}
        />
      </section>
    </>
  );
}
