import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;

const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export function Hero({
  children = null,
  color = "",
  carouselImages = [],
  image,
}) {
  const [imageIndex, setImageIndex] = useState(0);
  const [direction, setDirection] = useState(1);

  const isAnimatingRef = useRef(false);

  const isCarousel = carouselImages.length > 1;

  const img = isCarousel ? carouselImages[imageIndex].node : image.node;
  const src = isCarousel ? carouselImages[imageIndex].src : image.src;

  useEffect(() => {
    if (isCarousel) {
      const timeout = setTimeout(() => {
        handleNextPage(1);
      }, 4000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [carouselImages.length, direction, imageIndex]);

  useEffect(() => {
    setImageIndex(0);
  }, [carouselImages.length]);

  function handleNextPage(direction) {
    if (isAnimatingRef.current) {
      return;
    }

    isAnimatingRef.current = true;

    setImageIndex((index) => {
      if (direction > 0) {
        let nextIndex = index + 1;

        if (nextIndex > carouselImages.length - 1) {
          nextIndex = 0;
        }
        return nextIndex;
      } else {
        let nextIndex = index - 1;

        if (0 > nextIndex) {
          nextIndex = carouselImages.length - 1;
        }
        return nextIndex;
      }
    });
    setDirection(direction);
    setTimeout(() => (isAnimatingRef.current = false), 300);
  }

  return (
    <div className={`hero hero--${color}`}>
      {isCarousel ? (
        <div>
          <AnimatePresence initial={false} custom={direction}>
            <motion.img
              key={imageIndex}
              className="hero__img hero__img--animated"
              src={src}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 400, damping: 35 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
                if (swipe < -swipeConfidenceThreshold) {
                  handleNextPage(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  handleNextPage(-1);
                }
              }}
            ></motion.img>
          </AnimatePresence>
          <div className="hero__controls">
            <div className="hero__controls-inner">
              <div className="hero__controls-indexes">
                {carouselImages.map((_, i) => {
                  const active = i === imageIndex;
                  return (
                    <React.Fragment key={i}>
                      <button
                        className={`hero__control-index ${
                          active && `hero__control-index--active`
                        }`}
                        onClick={() => setImageIndex(i)}
                      >
                        {String(i + 1).padStart(2, "0")}
                      </button>
                      {i !== carouselImages.length - 1 && (
                        <div
                          className={`hero__control-line ${
                            active && `hero__control-line--active`
                          }`}
                        ></div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
              <button
                className="hero__control hero__control--next"
                onClick={() => handleNextPage(1)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                  <path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>{img}</div>
      )}
      <div className="hero__gradient"></div>
      <div className="hero__content">{children}</div>
    </div>
  );
}
